<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-card-header>
      <div>
        <b-card-title> Top 10 Outlets Epurse Redeemed</b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row class="mx-0">
        <b-col
            md="12"
            class="revenue-report-wrapper"
        >
          <b-table striped hover :items="outlets" :fields="columns">
            <template v-slot:cell(outletName)="data">
              <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/outletProfile/'+data.item.id+'?start_date='+start_date+'&end_date='+end_date">{{ data.value  }}</a>
            </template>
            <template v-slot:cell(totalPoints)="data">
              <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/outletProfile/'+data.item.id+'?start_date='+start_date+'&end_date='+end_date">{{ data.value | number('0,0') }}</a>
            </template>
          </b-table>

        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as moment from "moment/moment";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    DatePicker
  },
  directives: {
    Ripple,
  },
  props: {
    start_date:{
      type: String,
      required: true
    },
    end_date:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      columns:['outletName','totalPoints'],
      transactionType:'REDEMPTION',
      outlets:[]
    }
  },
  mounted (){
  },
    methods: {
      ...mapActions(["getOutletPerformance"]),

      getOutletPerformanceData(){
        let self = this;

        this.isLoading = true;

        let endDate = this.end_date;

        if(!endDate) {
          endDate = moment().format('YYYY-MM-DD');
        }

        this.getOutletPerformance({startDate:this.start_date, endDate:endDate,transactionType:this.transactionType})
            .then(function (outlets) {
              outlets.length = 10
              self.isLoading = false;
              self.outlets = outlets;

            })
            .catch(function (error) {

              self.isLoading = false;

              console.log(error)
            })
      },
    },
  }
</script>
