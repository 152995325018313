<template>
  <div>
    <b-card
            class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Statistics</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
                  md="4"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-primary"
                >
                  <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class=" mb-0">
                  E-purse Balance
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  KES. {{ stats.redeemable_value | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  md="4"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-success"
                >
                  <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="mb-0">
                  Accrued Points - Pending
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  {{ stats.total_points_accrued | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  md="4"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-danger"
                >
                  <feather-icon
                          size="24"
                          icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="mb-0">
                  Redeemed Value
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  KES. {{ stats.redeemed_value | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col
                  md="4"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-info"
                >
                  <feather-icon
                          size="24"
                          icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class=" mb-0">
                  New Registered Customers
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  {{ stats.total_customers | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="4"
              sm="6"
              class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-info"
                >
                  <feather-icon
                      size="24"
                      icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="mb-0">
                  Redeemed Value Partners
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  KES. {{ stats.redeemed_value_partners | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              xl="4"
              sm="6"
              class="mb-2 mb-xl-0 "
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-primary"
                >
                  <feather-icon
                      size="24"
                      icon="TrendingUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="mb-0">
                  Mpesa Parking Checkouts
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  Transactions: {{ stats.mpesa_parking_checkouts.total_transactions | number('0,0') }} <br/>
                  Amount: KES. {{ stats.mpesa_parking_checkouts.total_amount | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col
              xl="4"
              sm="6"
              class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="mb-0">
                  Epurse Parking Checkouts
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  Transactions: {{ stats.epurse_parking_checkouts.total_transactions | number('0,0') }} <br/>
                  Amount: KES. {{ stats.epurse_parking_checkouts.total_amount | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              xl="4"
              sm="6"
              class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-info"
                >
                  <feather-icon
                      size="24"
                      icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="mb-0">
                  Voucher Parking Checkouts
                </h4>
                <b-card-text class="font-medium-1 mb-0">
                  Transactions: {{ stats.voucher_parking_checkouts.total_transactions | number('0,0') }} <br/>
                  Amount: KES. {{ stats.voucher_parking_checkouts.total_amount | number('0,0') }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import { mapGetters, mapActions } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as moment from 'moment'
import Vue2Filters from 'vue2-filters'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    DatePicker,
    Vue2Filters
  },

  data() {
    return {
      dashboard_filter:{
        start_date:"2023-07-01",
        end_date:""
      },
      stats:{
        redeemable_value:0,
        redeemed_value:0,
        total_points_accrued:0,
        total_customers:0,
        total_outlets:0,
        new_registrations:0,
        new_registrations_today:0,
        parking_checkouts:0,
        mpesa_parking_checkouts:{
          total_amount:0,
          total_transactions:0
        },
        epurse_parking_checkouts:{
          total_amount:0,
          total_transactions:0
        },
        voucher_parking_checkouts:{
          total_amount:0,
          total_transactions:0
        },
        wallet_checkouts:0,
        redeemed_value_partners: 0
      }
    }
  },
  methods: {
    ...mapActions(["getHomepageStats", "getTopPerformingOutlets"]),
    disabledDatesFrom(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today ;
    },
    disabledDatesTo(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today ;
    },
    getHomepageData(){
      let self = this;
      let endDate = this.end_date;

      if(!endDate) {
        endDate = moment().format('YYYY-MM-DD');
      }

      this.getHomepageStats({start_date:this.start_date, end_date:endDate })
              .then(function (stats) {

                self.stats = stats;
              })
              .catch(function (error) {
                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    //self.$router.push({ name: 'auth-login'})

                  }

                }
              })
    },
  },
  props: {
    start_date:{
      type: String,
      required: true
    },
    end_date:{
      type: String,
      required: true
    }
  }
}
</script>
