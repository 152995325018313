<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-card-header>
      <div>
        <b-card-title>Outlet Category Accrual Performance</b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row class="mx-0">
        <b-col
            md="12"
            class="revenue-report-wrapper"
        >
          <vue-apex-charts
              type="pie"
              height="400"
              :options="chartOptions"
              :series="series"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueApexCharts from 'vue-apexcharts'
import * as moment from "moment";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    DatePicker,
    VueApexCharts
  },
  directives: {
    Ripple,
  },
  props: {
    start_date:{
      type: String,
      required: true
    },
    end_date:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      columns:['outletName','totalPoints'],
      transactionType:'ACCRUAL',
      outlets:[],
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  methods: {
    ...mapActions(["getOutletCategoryPerformanceStats"]),

    getOutletCategoryPerformanceStatsData(){
      let self = this;

      this.isLoading = true;

      let endDate = this.end_date;

      if(!endDate) {
        endDate = moment().format('YYYY-MM-DD');
      }

      this.getOutletCategoryPerformanceStats({start_date: this.start_date, end_date:endDate})
          .then(function (categories) {
            self.isLoading = false;
            let series = [];
            let labels = []
            categories.forEach(category => {
              labels.push(category.label)
              series.push(category.value)
            })

            self.series.splice(0, self.series.length, ...series);
            self.chartOptions.labels.splice(0, self.chartOptions.labels.length, ...labels);

          })
          .catch(function (error) {

            self.isLoading = false;

            console.log(error)
          })
    },
  }
  }
</script>
