<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h5 class="card-title mb-50 mb-sm-0">
            Loyalty Members
          </h5>
        </div>

        <table class="table table-striped">
          <tr>
            <th>Total Active Members</th>
            <td><a style="text-decoration: underline; font-weight: bolder;" href="/apps/customers?customerStatus=ACTIVE">{{ loyaltyMembersSummary.totalMembers | number('0,0')  }}</a></td>
          </tr>
          <tr>
            <th>Privileged Members</th>
            <td><a style="text-decoration: underline; font-weight: bolder;" href="/apps/customers?customerStatus=ACTIVE&customerTier=PREMIUM">{{ loyaltyMembersSummary.privilegedMembers | number('0,0')  }}</a></td>
          </tr>
          <tr>
            <th>Standard Members</th>
            <td><a style="text-decoration: underline; font-weight: bolder;" href="/apps/customers?customerStatus=ACTIVE&customerTier=STANDARD">{{ loyaltyMembersSummary.standardMembers | number('0,0')  }}</a></td>
          </tr>
          <tr>
            <th>Pending Members (Incomplete Registration)</th>
            <td><a style="text-decoration: underline; font-weight: bolder;" href="/apps/customers?customerStatus=INCOMPLETE">{{ loyaltyMembersSummary.pendingMembers | number('0,0')  }}</a></td>
          </tr>
          <tr>
            <th>Number of CUG</th>
            <td><a style="text-decoration: underline; font-weight: bolder;" href="/apps/customers?isCUG=true">{{ loyaltyMembersSummary.noOfCUG | number('0,0')  }}</a></td>
          </tr>
        </table>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex";
import * as moment from "moment";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    start_date:{
      type: String,
      required: true
    },
    end_date:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      startDate:'all',
      endDate:'all',
      loyaltyMembersSummary:{
        totalMembers:0,
        privilegedMembers:0,
        standardMembers:0,
        pendingMembers:0,
        noOfCUG:0
      },
    }
  },
  mounted (){
    //this.getLoyaltyMembersSummaryData();
  },
  methods: {
    ...mapActions(["getLoyaltyMembersSummary"]),

    getLoyaltyMembersSummaryData(){
      let self = this;
      let endDate = this.end_date;

      if(!endDate) {
        endDate = moment().format('YYYY-MM-DD');
      }

      this.getLoyaltyMembersSummary({startDate: this.startDate, endDate : endDate})
          .then(function (loyaltyMembersSummary) {

            self.loyaltyMembersSummary = loyaltyMembersSummary;

          })
          .catch(function (error) {


            if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }

            }
          })
    },
  }
  }
</script>
